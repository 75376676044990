import React from 'react';
import clsx from 'clsx';

const TitleBorder = ({
  className,
  children,
  ...other
  }) => {
  return <div className={clsx('title-border', className)} {...other}>{children}</div>
}

export default TitleBorder
