import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import clsx from 'clsx';

import Img from '@common/Image';
import OpenModalButton from '@common/OpenModalButton';
import PlayIcon from '@common/PlayIcon';

// MODAL STYLES
import '@node_modules/react-modal-video/scss/modal-video.scss';
import { playButton, videoContainer } from '@styles/modules/homepage.module.scss';

const Video = ({ children, videoId, image, imageWidth, color = 'primary', ...other }) => {
  const [isOpen, setOpened] = useState(false);
  const isModalOpen = isOpened => {
    setOpened(isOpened);
  }
  return (
    <>
      <ModalVideo
        channel={`youtube`}
        isOpen={isOpen}
        videoId={videoId}
        onClose={() => setOpened(false)}
      />
      <OpenModalButton getModalState={isModalOpen} videoId={videoId} {...other}>
        {image ?
          <div className={clsx(videoContainer, imageWidth)}>
            <Img image={image.gatsbyImageData} file={image.file}/>
            <PlayIcon color={color} className={playButton}/>
            <span className='sr-only sr-only-focusable'>{'Play Video'}</span>
          </div>
          :
          children
        }
      </OpenModalButton>
    </>
  )
}

export default Video
