import React from 'react';

import { saveQuiz } from '@redux/quiz/actions';
import { quizFormNextStep } from '@redux/quiz/actions';
import { useDispatch } from 'react-redux';

import { useForm, useFieldArray } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import Heading from '@common/Heading';
import Row from '@common/Row';

const options = [
  { 
    id: 'prodQuizGoals1TeacherEngagement',
    key: 'Adopt new ways to increase teacher engagement in the classroom',
    value: 'core'
  },
  {
    id: 'prodQuizGoals2ParentComms',
    key: 'Improve communication with parents',
    value: 'core'
  },
  { 
    id: 'prodQuizGoals3Admin',
    key: 'Save time on administrative duties such as billing & payments or waitlist management.',
    value: 'core'
  },
  { 
    id: 'prodQuizGoals4TeacherSkills',
    key: 'Elevate your practice and grow the skills of your teachers',
    value: 'academy'
  },
  { 
    id: 'prodQuizGoals5Activities',
    key: 'Introduce new and exciting educational materials and activities into the classroom',
    value: 'curriculum'
  },
  { 
    id: 'prodQuizGoals6LessonPlanning',
    key: 'Reduce time spent lesson planning',
    value: 'digital curriculum'
  },
  { 
    id: 'prodQuizGoals7Attendance',
    key: 'Manage child attendance more effectively',
    value: 'core'
  },
  { 
    id: 'prodQuizGoals8Insights',
    key: 'Gain more insight into my business to better manage the day-to-day of my center',
    value: 'core'
  }
];

const StepOne = ({ nextStep }) => {
  const { control, register, formState: { errors }, handleSubmit } = useForm({
    defaultValues: {
      recommend: [{}]
    }
  });
  const { fields } = useFieldArray({
    control,
    name: 'recommend',
  });
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    dispatch(saveQuiz(data));
    dispatch(quizFormNextStep());
    nextStep()
  };
  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Heading type='h2' className='fs-32'>{'What are your current goals?'}</Heading>
      <p className='fs-16 mb-3'>{'What are you hoping to achieve as you plan for the next school year?'}</p>
      <p className='fs-16'>{'Select as many as you like:'}</p>
      <Row className='row-cols-lg-2 gy-2 mb-5'>
        {options.map((item,index) => (
          <div key={index} className='demo-quiz-checkbox'>
            {fields.map((field) => (
              <Form.Check key={field.id}>
                <Form.Check.Input type={'checkbox'} value={item.id} {...register(`recommend`, { validate: v => v.length > 0 && v[0].constructor !== Object })}/>
                <Form.Check.Label className='ms-1 fs-12'>{item.key}</Form.Check.Label>
              </Form.Check>
            ))}
          </div>
        ))}
      </Row>
      {errors.recommend &&
        <Alert variant={'primary'}>
          {'At least one option is required'}
        </Alert>
      }
      <div className='d-flex justify-content-end'>
        <button type='submit' className='btn btn-primary btn-lg btn-pink-bright'>{'Next'}</button>
      </div>
    </Form>
  )
}

export default StepOne
