import React, { useState, useEffect } from 'react';

import Modal from 'react-bootstrap/Modal';
import StepWizard from 'react-step-wizard';
import { useDispatch, useSelector } from 'react-redux';
import { quizFormCurrentStep } from '@redux/quiz/actions';
import { useLocation } from '@gatsbyjs/reach-router';
import { navigate } from 'gatsby';

import MarketoForm from '@common/MarketoForm';
import { isBrowser, isProduction } from '@helpers/utils';

import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';

import * as styles from './demo-quiz.module.scss';

const DemoQuiz = ({ data, show, close, step }) => {
  const {
    size,
    bgColour,
    body
  } = data;
  const dispatch = useDispatch();
  const location = useLocation();
  const [prefill, setPrefill] = useState(null);
  const [leadSent, setLeadSent] = useState(false);
  const [resultsSent, setResultsSent] = useState(false);
  const [urlOpen, setUrlOpen] = useState(false);
  const user = useSelector(state => state.user);
  const quiz = useSelector(state => state.quiz);
  const handleClose = () => {
    setUrlOpen(false);
    close()
  }
  useEffect(() => {
    dispatch(quizFormCurrentStep(step || 1));
  }, [show]);
  useEffect(() => {
    if (quiz.submitLead && !leadSent) {
      setPrefill({
        FirstName: user.firstName,
        LastName: user.lastName,
        Email: user.email,
        Title: user.title,
        Phone: user.phone,
        prodQuizGoals1TeacherEngagement: quiz.recommend.findIndex(x => x === 'prodQuizGoals1TeacherEngagement') !== -1,
        prodQuizGoals2ParentComms: quiz.recommend.findIndex(x => x === 'prodQuizGoals2ParentComms') !== -1,
        prodQuizGoals3Admin: quiz.recommend.findIndex(x => x === 'prodQuizGoals3Admin') !== -1,
        prodQuizGoals4TeacherSkills: quiz.recommend.findIndex(x => x === 'prodQuizGoals4TeacherSkills') !== -1,
        prodQuizGoals5Activities: quiz.recommend.findIndex(x => x === 'prodQuizGoals5Activities') !== -1,
        prodQuizGoals6LessonPlanning: quiz.recommend.findIndex(x => x === 'prodQuizGoals6LessonPlanning') !== -1,
        prodQuizGoals7Attendance: quiz.recommend.findIndex(x => x === 'prodQuizGoals7Attendance') !== -1,
        prodQuizGoals8Insights: quiz.recommend.findIndex(x => x === 'prodQuizGoals8Insights') !== -1
      });
      setLeadSent(true);
    }
    if (quiz.submitResults && !resultsSent) {
      // Converts string to boolean
      const currentSolution = user.currentSolution == 'true';
      setPrefill({
        Email: user.email,
        Company: user.centerName,
        stateProvince: user.stateProvince,
        prodQuizCurrentlyUsingAnotherPlatform: currentSolution,
        prodQuizResult: quiz.result
      });
      setResultsSent(true);
    }
  }, [user, quiz]);
  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const open = search.get('quiz');
    setUrlOpen(open === 'open');
    search.delete('quiz');
    navigate(`?${search.toString()}`)
  }, []);
  useEffect(() => {
    if (location.pathname != '/features/pricing') {
      setUrlOpen(false);
    }
  }, [location.pathname]);
  return (
    <Modal show={show || urlOpen} onHide={handleClose} size={size} centered contentClassName='overflow-hidden bg-white'>
      <Modal.Header className='pb-0' closeButton/>
      {quiz.step < 4 &&
        <div className='modal-header flex-column flex-lg-row py-0 justify-content-start'>
          <p className='text-uppercase mb-0 me-2'>{`Step ${quiz.step} of 3`}</p>
          <div className={styles.progressBar}>
            <div className={styles.innerProgressBar} style={{width: `${(quiz.step/3 * 100)+1}%`}}/>
          </div>
        </div>
      }
      <Modal.Body>
        <StepWizard initialStep={step || 1}>
          <StepOne/>
          <StepTwo/>
          <StepThree/>
          <StepFour close={close}/>
        </StepWizard>
        {/* Submit lead details */}
        {quiz.submitLead &&
          <div className='sr-only' aria-hidden>
            <MarketoForm formId={'2308'} prefill={prefill} submit={quiz.submitLead} noRefresh/>
          </div>
        }
        {/* Submit final results */}
        {quiz.submitResults &&
          <div className='sr-only' aria-hidden>
            <MarketoForm formId={'2309'} prefill={prefill} submit={quiz.submitResults} noRefresh/>
          </div>
        }
      </Modal.Body>
    </Modal>
  )
}

export default DemoQuiz
