import React from 'react';
import clsx from 'clsx';

import Heading from '@common/Heading';
import Img from '@common/Image';
import Link from '@common/Link';

const HighlightLink = ({ data }) => {
  const {
    icon,
    bgColor,
    title,
    subtitle,
    link,
    linkCopy
  } = data;
  return (
    <div className={clsx('d-flex flex-column card scale-025 p-25 h-100', {[`bg-${bgColor}`]:bgColor})}>
      {icon &&
        <div className='me-auto mb-2'>
          <Img image={icon.gatsbyImageData} file={icon.file} width={28} height={28}/>
        </div>
      }
      {title && <Heading type='h3' style={{maxWidth: 'min-content'}}>{title}</Heading>}
      {subtitle && <p className='fs-16'>{subtitle}</p>}
      {(link && linkCopy) &&
        <Link className='text-black mt-auto' to={link}>
          {linkCopy}
        </Link>
      }
    </div>
  )
}

export default HighlightLink
