import React, { useEffect } from 'react';
import clsx from 'clsx';
import { navigate } from 'gatsby';

import InputGroup from 'react-bootstrap/InputGroup' ;
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { useLocation } from '@gatsbyjs/reach-router';
import { toggleOverlay } from '@redux/header/actions';
import { useDispatch } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

import * as styles from './blog-header.module.scss';

const SearchBar = () => {
  const { register, handleSubmit, setValue } = useForm();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const params = new URLSearchParams(search);
  const query = params.get('q');
  const popSearchesTxt = 'Popular searches:';
  const popularSearches = ['Employee Retention', 'Online Billing', 'COVID-19', 'Activities', 'Child Development', 'Leadership', 'Preschool'];
  const onSubmit = data => {
    params.set('q', data.query);
    dispatch(toggleOverlay(false));
    navigate(`/blog/search?${params.toString()}`);
  };
  const setSearch = data => {
    setValue('query', data);
    onSubmit({query: data});
  }
  useEffect(() => {
    // Set value from query on page load
    setValue('query', query);
  }, [query]);
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputGroup className={styles.blogSearchForm}>
          <InputGroup.Text id='searchBar' className='text-center border border-0' style={{background: 'none', margin: '2px -5px 0 10px'}}>
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              size={'xl'}
            />
          </InputGroup.Text>
          <Form.Control className={clsx('shadow-none', styles.searchBar)} placeholder='Search' {...register('query', { required: true })}/>
        </InputGroup>
      </Form>
      <div className={'d-none d-lg-flex w-100 mt-2 pb-1'}>
        {popSearchesTxt}
        {popularSearches.map((searches, index) => (
          <button key={index} style={{marginLeft:'15px'}} className='btn-link' onClick={() => setSearch(searches)}>{searches}</button>
        ))}
      </div>
    </>
  )
}

export default SearchBar
