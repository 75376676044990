import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import clsx from 'clsx';
import { isBrowser } from '@helpers/utils';
import { CSSTransition } from 'react-transition-group';
import { globalHistory } from '@gatsbyjs/reach-router'

import Container from '@common/Container';
import Link from '@common/Link';
import Img from '@common/Image';
import Button from '@common/Button';

import MobileNavButton from '@components/header/MobileNavButton';
import Nav from '@components/header/Nav';

import MobileNav from './MobileNav';

import * as styles from '@styles/modules/nav.module.scss';
import { header, btnStartNow, navMidHeader } from './styles.module.scss';

const Header = () => {
  const data = useStaticQuery(graphql`{
    contentfulLayout(title: {eq: "Summit"}) {
      ...Header
    }
  }`);
  const {
    startButton,
    loginButton,
    headerLogo,
    mobileHeaderLogo,
    navigation
  } = data?.contentfulLayout;
  const [openMobile, setOpenMobile] = useState(false);
  const toggle = () => setOpenMobile(!openMobile);
  useEffect(() => {
    if (isBrowser()) {
      window.addEventListener('resize', () => {
        if (window.innerWidth > 1200) {
          setOpenMobile(false);
        }
      });
    }
  }, []);
  useEffect(() => {
    // Adds an event listener for a route change
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        setOpenMobile(false);
      }
    })
  }, []);
  return (
    <Container className='position-relative'>
      <header className={clsx('bg-white p-3', header)}>  
        <nav id={`mainNav`}>
          <div className='d-flex flex-row justify-content-between align-items-center'>
            <div className={`d-flex flex-row`}>
              {headerLogo &&
                <Link to={`/bts-summit`} className={clsx(`d-flex align-items-center`, styles.imgLogo)} title='Homepage'>
                  <Img image={headerLogo?.gatsbyImageData} file={headerLogo?.file} className={`d-none d-xl-block`} imgStyle={{maxHeight: 46}}/>
                  <Img image={mobileHeaderLogo?.gatsbyImageData} file={mobileHeaderLogo?.file} className={`d-xl-none`} imgStyle={{maxHeight: 52, maxWidth: 56}}/>
                </Link>
              }
            </div>
            <div className={clsx(`d-flex align-items-center`, navMidHeader)}>
              {navigation && <Nav data={navigation} />}
              {loginButton &&
                <Button
                  to={loginButton.link}
                  size={loginButton.size}
                  variant={loginButton.variant}
                  addClass={clsx('scale-1 ms-4 me-2 d-none d-xl-block', loginButton.class)}
                  copy={loginButton.copy}
                />
              }
              {startButton &&
                <Button
                  to={startButton.link}
                  size={startButton.size}
                  variant={startButton.variant}
                  addClass={clsx('scale-1', btnStartNow, startButton.class)}
                  copy={startButton.copy}
                  modal={startButton.modal}
                />
              }
            </div>
            <div className={`position-relative d-xl-none`}>
              <MobileNavButton onClick={toggle} open={openMobile} />
            </div>
          </div>
          <CSSTransition
            classNames='fade'
            in={openMobile}
            timeout={300}
            unmountOnExit
            mountOnEnter
            >
            <MobileNav timeout={300} data={data} navigation={navigation} />
          </CSSTransition>
        </nav>
      </header>
    </Container>
  )
}

export default Header
