import React from 'react';

import Container from '@common/Container';
import Row from '@common/Row';
import Translate from '@common/Translate';

import FooterLogoColumn from './FooterLogoColumn';
import FooterLinks from './FooterLinks';

const TopFooter = ({ data }) => {
  const { footerBannerImage } = data?.contentfulLayout;
  return (
    <>
      <div style={{
        height: 149.1,
        background: `url(${footerBannerImage?.file?.url})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'repeat-x'
      }}/>
      <Container>
        <Row className='py-3'>
          <FooterLogoColumn data={data} />
          <FooterLinks data={data} />
        </Row>
        <Translate/>
      </Container>
    </>
  )
}

export default TopFooter
