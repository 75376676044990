import React from 'react';
import clsx from 'clsx';

import Link from '@common/Link';
import Img from '@common/Image';

import * as styles from '@styles/modules/nav.module.scss';

const DropdownLink = ({ link }) => {
  return (
    <Link to={link.link} className={clsx('d-flex flex-row align-items-center fw-medium fs-14 mb-1', link.class)}>
      {link.image && <Img image={link.image.gatsbyImageData} file={link.image.file} className={styles.dropdownImg}/>}
      {link.title && <div className='ms-1 text-black'>{link.title}</div>}
    </Link>
  )
}

export default DropdownLink
