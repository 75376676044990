import React from 'react';

import Heading from '@common/Heading';
import Video from '@common/Video';

const Default = ({ data }) => {
  const {
    title,
    showTitle,
    image,
    imageWidth,
    videoId
  } = data;
  return (
    <>
      {(showTitle && title) && <Heading type='h2' className='my-1'>{title}</Heading>}
      <Video videoId={videoId} image={image} imageWidth={imageWidth}/>
    </>
  )
}

export default Default
