import React from 'react';
import Container from '@common/Container';
import Row from '@common/Row';
import Logo from '@images/footer/lillio.svg';

const Footer = () => {
  return (
    <footer className='bg-dark-teal py-3'>
      <Container>
        <Row className='justify-content-center align-items-center'>
          <div className='text-center'>
            <img src={Logo} alt='Lillio' />
          </div>
        </Row>  
      </Container>
    </footer>
  )
}

export default Footer
