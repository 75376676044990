import { useStaticQuery, graphql } from 'gatsby';

const Query = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulBookADemo {
        findYourPlan {
          ... on ContentfulBundle {
            ...Bundle
          }
          ... on ContentfulProduct {
            ...Product
          }
        }
      }
    }
  `)
  return data
}

export default Query
