import React from 'react';

import Default from '@common/modal/Default';
import SnapshotReport from '@common/modal/snapshot-report/SnapshotReport';
import Subsidy from '@common/modal/subsidy/Subsidy';
import DemoQuiz from '@common/modal/demo-quiz/DemoQuiz';

const components = [
  {
    component: Default,
    key: 'Default'
  },
  {
    component: SnapshotReport,
    key: 'Snapshot Report'
  },
  {
    component: Subsidy,
    key: 'Subsidy Management'
  },
  {
    component: DemoQuiz,
    key: 'Demo Quiz'
  }
];

const Modal = ({ data, show, close }) => {
  const { style } = data;
  const Component = components.find(x => x.key === style)?.component || Default;
  return <Component data={data} show={show} close={close}/>;
}

export default Modal
