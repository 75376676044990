import React from 'react';
import { SkipNavLink, SkipNavContent } from '@reach/skip-nav';

import DarkOverlay from '@common/DarkOverlay';
import Header from '@components/summit/Header';
import Footer from '@components/summit/Footer';

const Summit = ({ children }) => {
  return (
    <>
      <Header />
      <DarkOverlay/>
      <SkipNavLink>{'Skip to Content'}</SkipNavLink>
      <SkipNavContent>{children}</SkipNavContent>
      <Footer />
    </>
  )
}

export default Summit
