import React from 'react';

import MarketoForm from '@common/MarketoForm';

const Default = ({ data }) => {
  const {
    title,
    showTitle,
    formId,
  } = data;
  return (
    <>
      {(showTitle && title) && <div className='h2 mb-3'>{title}</div>}
      {formId && <MarketoForm formId={formId} />}
    </>
  )
}

export default Default
