import React from 'react';
import clsx from 'clsx';

import Modal from 'react-bootstrap/Modal';

import Embedded from '@common/Embedded';
import Img from '@common/Image';
import Button from '@common/Button';

import * as styles from '@styles/modules/common.module.scss';


const Default = ({ data, show, close }) => {
  const {
    title,
    titleFontSize,
    titleAlignment,
    size,
    bgColour,
    body,
    images
  } = data;
  return (
    <Modal show={show} onHide={close} size={size} centered>
      <Modal.Header closeButton className={clsx('align-items-center')} style={{backgroundColor: '#FFFCF2'}}>
        <Modal.Title as='h3' className={clsx('px-1 pt-4 w-100', styles.modalTitle, {
            'fs-36': titleFontSize === '36px',
            'fs-32': titleFontSize === '32px',
            'fs-24': titleFontSize === '24px',
            'text-center': titleAlignment === 'Center',
            'text-end': titleAlignment === 'Right'
          })}>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={clsx('text-center')} style={{backgroundColor: '#FFFCF2'}}>
        <div className='modal-form px-1 pb-1'>
          {body && body.map((content, index) => (
            <Embedded key={index} data={content}/>
          ))}
          <div className='mx-auto mb-5'>
            <Button
              to={`/contact/demo`}
              variant={`primary`}
              size={`lg`}
              addClass={`text-black`}
              copy={`Request a demo`}
            />
          </div>
          <div className='col-10 mx-auto'>
            {images && images.map((image, index) => (
              <Img key={index} image={image.gatsbyImageData} file={image.file} />
            ))}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default Default
