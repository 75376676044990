import React, { useRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useLocation } from '@gatsbyjs/reach-router';

import Container from '@common/Container';
import Img from '@common/Image';
import Button from '@common/Button';
import RichText from '@common/RichText';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCircle } from '@fortawesome/free-solid-svg-icons';

import * as styles from '@styles/modules/footer.module.scss';

const Banner = ({ data, close }) => {
  const bannerBar = useRef();
  const location = useLocation();
  const [hide, setHide] = useState(false);
  const {
    title,
    showTitle,
    text,
    leftImg,
    rightImg,
    bgColour,
    rules,
    showRule
  } = data;
  useEffect(() => {
    if (rules) {
      setHide(!rules.some(x => location.pathname.startsWith(x)) === showRule);
    }
  }, [rules, location]);
  return (
    <div ref={bannerBar} style={{zIndex: 101}} className={clsx('p-2 px-lg-1 py-lg-0 d-flex align-items-center justify-content-between overflow-hidden', styles.bannerBar, { [`bg-${bgColour}`]: bgColour, 'd-none': hide })}>
      {leftImg &&
        <Img
          loading='eager'
          image={leftImg.gatsbyImageData}
          file={leftImg.file}
          alt={leftImg.description}
          className='d-none d-lg-block'
        />
      }
      <Container className='position-relative'>
        <div className='d-flex flex-column flex-sm-row align-items-center justify-content-center'>
          <div className='d-flex flex-row align-items-center mb-2 mb-md-0'>
            {(title && showTitle) && <p className='fw-semi-bold mb-0'>{title}</p>}
            {text && <RichText json={text} p='text-center fw-medium mb-0' a='text-black text-decoration-underline'/>}
            <Button type='button' className={styles.bannerClose} onClick={close}>
              <span className='fa-layers fa-fw'>
                <FontAwesomeIcon icon={faCircle} color='#fff' size='2x' style={{opacity: 0.35}}/>
                <FontAwesomeIcon icon={faTimes} color='#000' transform='right-6'/>
              </span>
              <span className='sr-only sr-only-focusable'>
                {'Close banner'}
              </span>
            </Button>
          </div>
        </div>
      </Container>
      {rightImg &&
        <Img
          loading='eager'
          image={rightImg.gatsbyImageData}
          file={rightImg.file}
          alt={rightImg.description}
          className='d-none d-lg-block'
        />
      }
    </div>
  )
}

export default Banner
