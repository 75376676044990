import { useStaticQuery, graphql } from 'gatsby';

const Query = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulFormFields {
        firstName
        lastName
        email
        phoneNumber
        titleLabel
        titleList
        parentRedirect {
          ...Link
        }
        nextButton
      }
    }
  `)
  return data
}

export default Query
