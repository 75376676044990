import React from 'react';
import { Script } from 'gatsby';

const Scripts = () => {
  const marketo = () => ({
    __html: `(function() {
            var didInit = false;
            function initMunchkin() {
              if(didInit === false) {
                didInit = true;
                Munchkin.init('184-QZH-075');
              }
            }
            var s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = '//munchkin.marketo.net/munchkin.js';
            s.onreadystatechange = function() {
              if (this.readyState == 'complete' || this.readyState == 'loaded') {
                initMunchkin();
              }
            };
            s.onload = initMunchkin;
            document.getElementsByTagName('head')[0].appendChild(s);
          })();`
  });
  const salesloft = () => ({
    __html: `(function(i,s,o,g,r,a,m){i['SLScoutObject']=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m.nextSibling)
            })(window,document,'script','https://scout-cdn.salesloft.com/sl.js','slscout');
            slscout(["init", "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0IjoxMDI1MjB9.jNpt1wIVAJOqNXJqjaC9_8gyx4p75VAFofYnmn3KwbE"]);`
  });
  return (
    <>
      <Script id='crazyegg' src='//script.crazyegg.com/pages/scripts/0090/7704.js' strategy='idle'/>
      <Script id='salesloft' dangerouslySetInnerHTML={salesloft()} strategy='idle'/>
      <Script id='marketo' dangerouslySetInnerHTML={marketo()} strategy='idle'/>
      <Script id='unbounce' src='https://dd317e587a934b1690eb0336440609b3.js.ubembed.com' strategy='idle'/>
      <Script id='adobe1' src='https://assets.adobedtm.com/175f7caa2b90/963761ab2508/launch-cc1c462effbe.min.js' async strategy='post-hydrate'/>
      <Script id='adobe2' src='https://assets.adobedtm.com/175f7caa2b90/fe3586228ed4/launch-670c28a34a6f.min.js' async strategy='post-hydrate'/>
    </>
  )
}

export default Scripts
