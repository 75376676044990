import React from 'react';

const Heading = ({ children, type, ...other }) => {
  const Tag = type || 'h2';
  return (
    <Tag {...other}>{children}</Tag>
  )
}

export default Heading
