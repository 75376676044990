import React from 'react';
import clsx from 'clsx';

import Button from '@common/Button';

const Default = ({ data }) => {
  const {
    link,
    variant,
    size,
    copy,
    icon,
    iconSize,
    iconPosition,
    modal
  } = data;
  return (
    <Button
      to={link}
      variant={variant}
      size={size}
      addClass={clsx('scale-1 mt-3', data.class)}
      copy={copy}
      icon={icon}
      iconSize={iconSize}
      iconPosition={iconPosition}
      modal={modal}
    />
  )
}

export default Default
