import React from 'react';

import Img from '@common/Image';

import { benefitPerk } from '@styles/modules/careers.module.scss';

const Default = ({ data }) => {
  const {
    title,
    showTitle,
    images
  } = data;
  return (
    <>
      {(title && showTitle) && <Heading type='h2'>{title}</Heading>}
      <div className={`d-flex flex-wrap py-3`}>
        {images && images.map((image, index) => (
          <div key={index} className={benefitPerk}>
            {(image.gatsbyImageData || image.file) &&
              <Img image={image.gatsbyImageData} file={image.file} style={{maxWidth: '53px', maxHeight: '53px'}} aria-hidden={true} />
            }
            <p className={`mt-2`}>{image.title}</p>
          </div>
        ))}
      </div>
    </>
  )
}

export default Default
