import React from 'react';
import clsx from 'clsx';

import Row from '@common/Row';
import Heading from '@common/Heading';
import RichText from '@common/RichText';
import TitleBorder from '@common/TitleBorder';

const ColouredTitleBlocks = ({ data }) => {
  const {
    title,
    showTitle,
    list
  } = data;
  return (
    <>
      {(title && showTitle) && <Heading type='h2'>{title}</Heading>}
      <Row className={clsx('list-title-border m-0', {'row-cols-md-2':list.length > 3})}>
        {list && list.map((card, index) => (
          <div key={index} className={clsx('d-flex flex-column justify-content-center ms-1 ms-md-0 mb-3 py-2')} >
            <div className='px-1 position-relative'>
              <TitleBorder />
              <p className='mb-0'>{card.title}</p>
            </div>
            <RichText p='mt-3 mb-0 px-1' json={card.text}/>
          </div>
        ))}
      </Row>
    </>
  )
}

export default ColouredTitleBlocks
