import React from 'react';

import Default from '@common/embedded/list/Default';
import SocialLinks from '@common/embedded/list/SocialLinks';
import ColouredTextBlocks from '@common/embedded/list/ColouredTextBlocks';
import ColouredTitleBlocks from '@common/embedded/list/ColouredTitleBlocks';
import BorderedLinks from '@common/embedded/list/BorderedLinks';

const components = [
  {
    component: Default,
    key: 'Default'
  },
  {
    component: SocialLinks,
    key: 'Social Links'
  },
  {
    component: ColouredTextBlocks,
    key: 'Coloured Text Blocks'
  },
  {
    component: ColouredTitleBlocks,
    key: 'Coloured Title Blocks'
  },
  {
    component: BorderedLinks,
    key: 'Bordered Links'
  }
];

const ListEmbed = ({ data }) => {
  const { style } = data;
  const Component = components.find(x => x.key === style)?.component || Default;
  return <Component data={data}/>;
}

export default ListEmbed
