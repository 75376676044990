import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { quizFormSubmitResults, quizSetResult } from '@redux/quiz/actions';

import Heading from '@common/Heading';
import Row from '@common/Row';
import Button from '@common/Button';
import RichText from '@common/RichText';
import Query from '@queries/demo/StepFour';
import { isBrowser } from '@helpers/utils';

import Bundle from '@common/Bundle';
import Product from '@common/Product';

const options = [
  { 
    id: 'prodQuizGoals1TeacherEngagement',
    key: 'Adopt new ways to increase teacher engagement in the classroom',
    value: 'core'
  },
  {
    id: 'prodQuizGoals2ParentComms',
    key: 'Improve communication with parents',
    value: 'core'
  },
  { 
    id: 'prodQuizGoals3Admin',
    key: 'Save time on administrative duties such as billing & payments or waitlist management.',
    value: 'core'
  },
  { 
    id: 'prodQuizGoals4TeacherSkills',
    key: 'Elevate your practice and grow the skills of your teachers',
    value: 'academy'
  },
  { 
    id: 'prodQuizGoals5Activities',
    key: 'Introduce new and exciting educational materials and activities into the classroom',
    value: 'curriculum'
  },
  { 
    id: 'prodQuizGoals6LessonPlanning',
    key: 'Reduce time spent lesson planning',
    value: 'digital curriculum'
  },
  { 
    id: 'prodQuizGoals7Attendance',
    key: 'Manage child attendance more effectively',
    value: 'core'
  },
  { 
    id: 'prodQuizGoals8Insights',
    key: 'Gain more insight into my business to better manage the day-to-day of my center',
    value: 'core'
  }
];

const StepFour = ({ isActive, close }) => {
  const dispatch = useDispatch();
  const [plan, setPlan] = useState(null);
  const [recommendedValues, setRecommendedValues] = useState(null);
  const recommendations = Query();
  const quiz = useSelector(state => state.quiz);
  const user = useSelector(state => state.user);
  const scrollToTop = () => isBrowser() && window.scrollTo(0,0);
  const handleClose = () => {
    scrollToTop();
    close();
  }
  const buildValuesList = recommend => {
    const values = [];
    recommend.forEach((item) => {
      const option = options.find(x => x.id === item);
      if (option) {
        values.push(option.value);
      }
    })
    setRecommendedValues(values);
  }
  useEffect(() => {
    if (isActive) {
      const plans = recommendations?.contentfulBookADemo?.findYourPlan;
      const cores = recommendedValues?.filter(x => x === 'core')?.length;
      const academys = recommendedValues?.filter(x => x === 'academy')?.length;
      const curriculums = recommendedValues.filter(x => x === 'curriculum')?.length;
      const digitalCurriculums = recommendedValues?.filter(x => x === 'digital curriculum')?.length;

      let recommendedPlan = '';
      if (user.enrollmentRange === '1-20' || user.centerEnrolment === '0-5') {
        // Less than 1 always Family Care Package
        recommendedPlan = 'Family Childcare Package';
      } else {
        if (!cores && !curriculums && !digitalCurriculums && academys > 0) {
          // Only Academy
          recommendedPlan = 'Lillio Academy (formerly HiMama Academy)';
        } else if (!cores && !academys && !digitalCurriculums && curriculums > 0) {
          // Only Curriculum
          recommendedPlan = 'Lillio Learning powered by FunShine Curriculum Kits';
        } else if (!cores && !academys && !curriculums && digitalCurriculums > 0) {
          // Only Digital Curriculum
          recommendedPlan = 'Lillio Learning powered by FunShine Digital Curriculum';
        } else if (!academys && !curriculums && !digitalCurriculums && cores > 0) {
          // Only Core
          recommendedPlan = 'HiMama Center Management';
        } else if (!curriculums && !digitalCurriculums && cores > 0 && academys > 0) {
          // Core + Academy
          recommendedPlan = 'Educator + Package';
        } else if (!academys && !curriculums && cores > 0 && digitalCurriculums > 0) {
          // Core + Digital Curriculum
          recommendedPlan = 'Silver Center Package';
        } else if (!academys && !cores && curriculums > 0 && digitalCurriculums > 0) {
          // Curriculum + Digital Curriculum
          recommendedPlan = 'Curriculum Package';
        } else if (!curriculums && cores > 0 && academys > 0 && digitalCurriculums > 0) {
          // Core + Academy + Digital Curriculum
          recommendedPlan = 'Gold Center Package';
        } else if (cores > 0 && academys > 0 && curriculums > 0 && digitalCurriculums > 0) {
          // Core + Academy + Curriculum + Digital Curriculum
          recommendedPlan = 'Platinum Center Package';
        } else {
          recommendedPlan = 'Build your own plan!';
        }
      }
      const newPlan = plans.find(x => x.title === recommendedPlan);
      setPlan(newPlan);
      dispatch(quizSetResult(newPlan.title));
      dispatch(quizFormSubmitResults());
    }
  }, [isActive]);
  useEffect(() => {
    buildValuesList(quiz.recommend)
  }, [quiz.recommend])
  return (
    <Row className='flex-lg-row-reverse row-cols-lg-2 pb-3'>
      {plan &&
        <>
          <div className='col d-flex flex-column justify-content-between flex-fill p-lg-3 pb-3'>
            {plan.recommendation &&
              <div>
                <Heading type='h2' className='fs-28'>{plan.recommendation.title}</Heading>
                <RichText json={plan.recommendation.text} p='fs-14'/>
              </div>
            }
            <div>
              <Button
                variant='primary'
                copy='Get a Quote'
                to='/contact/demo'
                addClass='me-1'
              />
              <Button
                variant='outline-black'
                copy='See other plans'
                onClick={handleClose}
              />
            </div>
          </div>
          <div className='col d-flex flex-fill'>
            {plan.__typename === 'ContentfulBundle'&&
              <Bundle data={plan}/>
            }
            {plan.__typename === 'ContentfulProduct'&&
              <Product data={plan}/>
            }
          </div>
        </>
      }
    </Row>
  )
}

export default StepFour
