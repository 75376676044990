import React from 'react';

import RichText from '@common/RichText';

const Default = ({ data }) => {
  const {
    title,
    showTitle,
    text,
  } = data;
  return (
    <>
      {(showTitle && title) && <div className='h2 mb-3'>{title}</div>}
      {text && <RichText json={text} />}
    </>
  )
}

export default Default
