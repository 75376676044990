import React, { useState } from 'react';
import { Script } from 'gatsby';

const translate = () => ({
  __html: `function googleTranslateElementInit() {
            new google.translate.TranslateElement(
                {pageLanguage: 'en'},
                'google_translate_element'
            );
          }`
});

const Translate = () => {
  const [loaded, setLoaded] = useState(false);
  return (
    <>
      <Script id='gtranslate' src='https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit' onLoad={() => setLoaded(true)}/>
      {loaded && <Script id='translate' dangerouslySetInnerHTML={translate()}/>}
      <div id='google_translate_element' className='d-flex justify-content-end mb-3'/>
    </>
  )
}

export default Translate
