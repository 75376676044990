import React from 'react';

import List from '@common/embedded/ListEmbed';
import Image from '@common/embedded/ImageEmbed';
import Asset from '@common/embedded/AssetEmbed';
import Button from '@common/embedded/ButtonEmbed';
import Video from '@common/embedded/VideoEmbed';
import Form from '@common/embedded/FormEmbed';
import Text from '@common/embedded/TextEmbed';
import AcademyLink from '@common/embedded/AcademyLinkEmbed';

// List of dynamic components
const components = {
  ContentfulListBlock: List,
  ContentfulImageBlock: Image,
  ContentfulAsset: Asset,
  ContentfulButton: Button,
  ContentfulVideo: Video,
  ContentfulForm: Form,
  ContentfulAcademyForm: Form,
  ContentfulTextBlock: Text,
  ContentfulAcademyLink: AcademyLink
}

const Embedded = ({ data }) => {
  const Component = components[data?.__typename];
  if (Component) {
    return <Component data={data} />;
  }
  console.log('Missing component for type: ' + data?.__typename);
  return null;
}

export default Embedded
