import React from 'react';
import clsx from 'clsx';

import Heading from '@common/Heading';
import RichText from '@common/RichText';
import Button from '@common/Button';

import { splitAcademyTitle } from '@helpers/utils';

const Product = ({ data, showButton, setMinHeight }) => {
  const {
    title,
    description,
    color,
    button,
    includes
  } = data;
  return (
    <div className='d-flex flex-column p-3 shadow rounded position-relative overflow-hidden pop-in'>
      <div className={clsx({[`bg-${color}`]:color})} style={{position: 'absolute', height: '18px', top: '0', left: '0', right: '0'}}/>
      <Heading type='h2' className='fs-24 fw-semibold mt-3 mb-3' style={{whiteSpace: 'pre-wrap'}}>{splitAcademyTitle(title)}</Heading>
      <div className='mb-3' style={{minHeight: setMinHeight ? '150px':'null'}}>
        <RichText p='fs-16' json={description}/>
      </div>
      {(button && showButton) &&
        <Button
          variant={button.variant}
          to={button.link}
          size={button.size}
          addClass={clsx('shadow scale-1 mb-3', button.class)}
          copy={button.copy}
        />
      }
      {includes &&
        <div className='d-flex flex-column flex-fill'>
          <div className='mb-2 fs-16'>{'Includes:'}</div>
          <RichText ul='list-checkmark-black mb-0' p='fs-14' json={includes}/>
        </div>
      }
    </div>
  )
}

export default Product
