import React from 'react';

import NavLink from './NavLink';
import TextBox from './NavTextBox';

const components = { 
  ContentfulLink: NavLink,
  ContentfulTextBlock: TextBox
}


const NavColumn = ({ data }) => {
  return (
    <ul className={`list-unstyled d-flex flex-column col col-xl-4`}>
      {data.map((link,i) => {
        const Component = components[link?.__typename];
        return(
          <li key={i}>
            <Component data={link}/>
          </li>
        )
      })}
    </ul>
  )
}

export default NavColumn
