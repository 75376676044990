import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import BottomFooter from '@components/footer/BottomFooter';
import TopFooter from '@components/footer/TopFooter';
import Banners from '@components/footer/Banners';
import Popups from '@components/footer/Popups';

const Footer = () => {
  const data = useStaticQuery(graphql`{
    contentfulLayout(title: {eq: "Academy"}) {
      ...Footer
    }
  }`);
  return (
    <footer>
      <TopFooter data={data}/>
      <BottomFooter data={data}/>
      <Banners data={data}/>
      <Popups data={data}/>
    </footer>
  )
}

export default Footer
