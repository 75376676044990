import React from 'react';
import clsx from 'clsx';

import {
  btnMenu,
  checked
} from '@styles/modules/nav.module.scss';

const MobileNavButton = ({ open, onClick }) => {
  return (
    <button id={`mobileNavBtn`} onClick={onClick} className={clsx(btnMenu, {[checked]:open})} title='Navigation Menu'>
      <span></span>
      <span></span>
      <span></span>
    </button>
  )
}

export default MobileNavButton
