import React from 'react';

import Scripts from '@common/Scripts';
import { isProduction } from '@helpers/utils';

import Layout from './Layout';
import Academy from './AcademyLayout';
import Blog from './BlogLayout';
import Minimal from './MinimalLayout';
import Summit from './SummitLayout';
import Demo from './DemoLayout';

const components = [
  {
    component: Layout,
    key: 'Default'
  },
  {
    component: Academy,
    key: 'Academy'
  },
  {
    component: Blog,
    key: 'Blog'
  },
  {
    component: Minimal,
    key: 'Minimal'
  },
  {
    component: Summit,
    key: 'Summit'
  },
  {
    component: Demo,
    key: 'Demo'
  }
];

const Index = ({ children, pageContext }) => {
  const Component = components.find(x => x.key === pageContext.layout)?.component || Layout;
  if (Component) {
    return (
      <>
        {isProduction() && <Scripts />}
        <Component>{children}</Component>
      </>
    );
  }
  console.log('Missing component for type: ' + pageContext?.layout);
  return null;
}

export default Index
