import React from 'react';

const TopFooter = ({ data }) => {
  const { footerBannerImage } = data?.contentfulLayout;
  return (
    <div style={{
      height: 149.1,
      marginBottom: 3,
      background: `url(${footerBannerImage?.file?.url})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'repeat-x'
    }}/>
  )
}

export default TopFooter
