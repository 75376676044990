import React, { useLayoutEffect, useState } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

import Link from '@common/Link';
import Modal from '@common/Modal';

const icons = [
  {
    icon: faEnvelope,
    key: 'faEnvelope'
  },
  {
    icon: faPhoneAlt,
    key: 'faPhoneAlt'
  },
  {
    icon: faChevronRight,
    key: 'faChevronRight'
  }
];

const Button = ({
  variant,
  size,
  unbounce,
  addClass,
  copy,
  to,
  children,
  modal,
  icon,
  iconSize,
  iconPosition,
  ...other
  }) => {
  const Tag = to ? Link : 'button';
  const [show, setShow] = useState(false);
  const [faIcon, setFaIcon] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useLayoutEffect(() => {
    const newIcon = icons.find(x => x.key === icon)?.icon;
    setFaIcon(newIcon);
  }, [icons]);
  return (
    <>
      <Tag
        to={to}
        tabIndex={to && 0}
        className={clsx('btn', unbounce, addClass, {
          [`btn-${variant}`]: variant,
          [`btn-${size}`]: size,
          'd-inline-flex align-items-center justify-content-center': icon,
          'flex-row': icon && iconPosition === 'Right',
          'flex-row-reverse': icon && iconPosition === 'Left'
        })}
        onClick={handleShow}
        {...other}
      >
        {copy}
        {children}
        {faIcon &&
          <FontAwesomeIcon
            icon={faIcon}
            size={iconSize}
            className={clsx('ms-1', {
              'ms-1': iconPosition === 'Right',
              'me-1': iconPosition === 'Left'
            })}
          />
        }
        {modal && <span className='sr-only sr-only-focusable'>{'(opens pop-up modal)'}</span>}
      </Tag>
      {modal && <Modal data={modal} show={show} close={handleClose}/>}
    </>
  )
}

export default Button
