import { useStaticQuery, graphql } from 'gatsby';

const Query = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulFormFields {
        centerName
        enrollmentRangeLabel
        enrollmentRangeList
        centerEnrolmentLabel
        centerEnrolmentList
        titleRedirect {
          ...Link
        }
        nextButton
      }
    }
  `)
  return data
}

export default Query
