import React from 'react';
import clsx from 'clsx';

import Modal from 'react-bootstrap/Modal';

import Img from '@common/Image';
import Button from '@common/Button';
import Embedded from '@common/Embedded';

import * as styles from '@styles/modules/common.module.scss';


const Default = ({ data, show, close }) => {
  const {
    title,
    showTitle,
    titleFontSize,
    titleAlignment,
    textAlignment,
    size,
    bgColour,
    body,
    buttonCopy,
    buttonLink,
    images
  } = data;
  return (
    <Modal show={show} onHide={close} size={size} centered>
      <Modal.Header closeButton className={clsx('align-items-center', {[`bg-${bgColour}`]: bgColour})}>
        {showTitle &&
          <Modal.Title as='h3' className={clsx('px-1 pt-1 w-100', styles.modalTitle, {
            'fs-36': titleFontSize === '36px',
            'fs-24': titleFontSize === '24px',
            'text-center': titleAlignment === 'Center',
            'text-end': titleAlignment === 'Right'
            })}>
            {title}
          </Modal.Title>
        }
      </Modal.Header>
      <Modal.Body className={clsx({[`bg-${bgColour}`]: bgColour})}>
        <div className={clsx('modal-form px-1 pb-1', {
            'text-center': textAlignment === 'Center',
            'text-end': textAlignment === 'Right'
          })}>
          {body && body.map((content, index) => (
            <Embedded key={index} data={content}/>
          ))}
          {(buttonLink && buttonCopy) &&
            <div className='mt-4'>
              <Button
                to={buttonLink}
                variant={'primary'}
                copy={buttonCopy}
                />
            </div>
          }
          {images &&
            <div className='mt-4'>
              {images.map((image, index) => (
                <Img key={index} image={image.gatsbyImageData} file={image.file} />
              ))}
            </div>
          }
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default Default
