import { initialState } from '@redux/store';

const quizFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SAVE_QUIZ': 
      return {
        ...state,
        ...action.payload
      }
    case 'CURRENT_STEP':
      return {
        ...state,
        step: action.payload
      }
    case 'NEXT_STEP': 
      return {
        ...state,
        step: state.step + 1
      }
    case 'PREV_STEP':
      return {
        ...state,
        step: state.step - 1
      }
    case 'SET_RESULT':
      return {
        ...state,
        result: action.payload
      }
    case 'SET_CALENDLY':
      return {
        ...state,
        calendlyUrl: action.payload
      }
    case 'SET_REDIRECT':
      return {
        ...state,
        redirectUrl: action.payload
      }
    case 'SUBMIT_LEAD':
      return {
        ...state,
        submitLead: true
      }
    case 'SUBMIT_FORM':
      return {
        ...state,
        submitResults: true
      }
    default:
      return state
  }
}
export default quizFormReducer;
