import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { toggleSearch } from '@redux/blog/actions';
import { toggleOverlay } from '@redux/header/actions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faX } from '@fortawesome/free-solid-svg-icons';

const SearchButton = () => {
    const blog = useSelector(state => state.blog);
    const dispatch = useDispatch();
    const toggle = () => {
      dispatch(toggleSearch(!blog.searchBar))
      dispatch(toggleOverlay(!blog.searchBar));
    }
    return (
      <button onClick={toggle} className='border-0 bg-transparent ms-auto fa-layers fa-fw fa-2xl pe-2 pe-xl-0'>
        <FontAwesomeIcon
          icon={faCircle}
        />
        <FontAwesomeIcon
          icon={blog.searchBar ? faX : faMagnifyingGlass}
          transform='shrink-7'
          inverse
        />
      </button>
    )
  }
  
export default SearchButton
