import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import Link from '@common/Link';

const Default = ({ data }) => {
  const {
    title,
    link
  } = data;
  return (
    <Link to={link} className='text-black fw-semibold'>
      {title} <FontAwesomeIcon icon={faChevronRight} size='sm'/>
    </Link>
  )
}

export default Default
