import React from 'react';
import BootstrapRow from 'react-bootstrap/Row';

const Row = ({
  children,
  ...other
  }) => {
  return <BootstrapRow {...other}>{children}</BootstrapRow>
}

export default Row
