import React from 'react';

import Query from '@queries/quiz/StepTwo';
import { saveUser } from '@redux/user/actions';
import { quizFormNextStep, quizFormPrevStep, quizFormSubmitLead } from '@redux/quiz/actions';
import { useDispatch, useSelector } from 'react-redux';
import { StaticImage } from 'gatsby-plugin-image';

import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';

import Heading from '@common/Heading';
import Row from '@common/Row';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const StepTwo = ({ nextStep, previousStep }) => {
  const data = Query();
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    titleLabel,
    titleList
  } = data.contentfulFormFields;
  const { register, formState: { errors, isValid }, handleSubmit } = useForm({
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName ,
      email: user.email,
      phone: user.phone,
      title: user.title
    }
  });
  const onSubmit = (data) => {
    if (isValid) {
      dispatch(saveUser(data));
      dispatch(quizFormSubmitLead());
      dispatch(quizFormNextStep());
      nextStep()
    }
  };
  const handlePrevStep = () => {
    dispatch(quizFormPrevStep());
    previousStep();
  }
  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)} className='demo-quiz-form'>
      <Heading type='h2' className='fs-32 mb-3'>{'Let\'s learn more about you!'}</Heading>
      <Row>
        <div className='col-lg-7'>
          <Row>
            <div className='col'>
              <Form.Group controlId='firstName' className='mb-3 required'>
                <Form.Label>{firstName}</Form.Label>
                <Form.Control
                  name='firstName'
                  type='text'
                  required
                  placeholder={firstName}
                  isInvalid={!!errors.firstName}
                  {...register('firstName', { required: 'Please type in your first name.' })}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.firstName?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className='col'>
              <Form.Group controlId='lastName' className='mb-3 required'>
                <Form.Label>{lastName}</Form.Label>
                <Form.Control
                  name='lastName'
                  type='text'
                  required
                  placeholder={lastName}
                  isInvalid={!!errors.lastName}
                  {...register('lastName', { required: 'Please type in your last name.' })}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.lastName?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </Row>
          <Form.Group controlId='email' className='mb-3 required'>
            <Form.Label>{email}</Form.Label>
            <Form.Control
              name='email'
              type='email'
              required
              placeholder={email}
              defaultValue={user.email}
              isInvalid={!!errors.email}
              {...register('email', { required: 'An E-mail address is required', pattern: /\S+@\S+\.\S+/ })}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.email?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId='phoneNumber' className='mb-3 required'>
            <Form.Label>{phoneNumber}</Form.Label>
            <Form.Control
              name='phoneNumber'
              type='tel'
              required
              placeholder={phoneNumber}
              isInvalid={!!errors.phone}
              {...register('phone', { required: 'A phone number is required' })}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.phone?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId='title' className='mb-3 required'>
            <Form.Label>{titleLabel}</Form.Label>
            <Form.Select
              name='title'
              required
              isInvalid={!!errors.title}
              {...register('title', { required: 'This field is required' })}
            >
              <option value=''>{titleLabel}</option>
              {titleList && titleList.map((title, index) => (
                <option key={index} value={title}>{title}</option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type='invalid'>
              {errors.title?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className='col'>
          <StaticImage src={`../../../../images/demo/lets_chat_blocks.png`} placeholder='none' alt='' />
        </div>
      </Row>
      <div className='d-flex justify-content-between'>
        <button type='button' onClick={handlePrevStep} className='btn shadow-none'>
          <FontAwesomeIcon icon={faArrowLeft} size='lg'/>
          <span className='sr-only sr-only-focusable'>Previous Step</span>
        </button>
        <button type='submit' className='btn btn-primary btn-lg btn-pink-bright'>{'Next'}</button>
      </div>
    </Form>
  )
}

export default StepTwo
