import React from 'react';

import Row from '@common/Row';
import FooterLinksColumn from './FooterLinksColumn';

const FooterLinksColumns = ({ data }) => {
  const { footerLinks } = data?.contentfulLayout;
  return (
    <div className='col-lg-10 pt-4'>
      <Row className='row-cols-2 row-cols-sm-3 row-cols-xl-5'>
      {footerLinks && footerLinks.map((list, index) => (
        <FooterLinksColumn key={index} title={list?.title} links={list?.text}/>
      ))}
      </Row>
    </div>
  )
}

export default FooterLinksColumns
