import React from 'react';

const DropdownArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10.232" height="6.53" viewBox="0 0 10.232 6.53">
      <path id="Path_169" data-name="Path 169" d="M258.035-23.342l4.409,4.409,4.409-4.409" transform="translate(-257.328 24.049)" fill="none" stroke="#000" strokeWidth="2"/>
    </svg>
  )
}

export default DropdownArrow
